import Promo1 from "../../../images/promo/PromoNissan.png";
import PromoMobile1 from "../../../images/promo/PromoNissanMobile.png";
import Promo2 from "../../../images/promo/PromoBYDSealion.png";
import PromoMobile2 from "../../../images/promo/PromoBYDSealionMobile.png";
import PromoTesla from "../../../images/promo/PromoTesla3500K.png";
import PromoTeslaMobile from "../../../images/promo/PromoTesla3500KMobile.png";
import PromoBYDShark from "../../../images/promo/PromoBYDShark.png";
import PromoBYDSharkMobile from "../../../images/promo/PromoBYDSharkMobile.png";

const dataSlider = (hidePartnered) => [
  {
    isTextImage: false,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: true,
    hideBanner: hidePartnered, // true will remove this section from the slider
    titleBadge: "",
    vehicleTitle: "",
    vehicleSubTitle: "",
    priceDollar: "",
    priceCents: "",
    period: "",
    disclaimerText: "",
    image: "",
    imageMobile: "",
  },
  {
    isTextImage: false,
    isTextImageNoPrice: true,
    isFullImage: false,
    isPartner: false,
    hideBanner: false, // true will remove this section from the slider
    titleBadge: "Exclusive for novated leases",
    vehicleTitle1: "$3,500 OFF",
    vehicleTitle2: "Tesla model 3 when purchased through a novated lease",
    vehicleSubTitle: "Plus $4,000 OFF current inventory stock of the Tesla Model Y",
    disclaimerText:
      `Purchase an eligible Tesla Model 3 by way of novated lease or purchase a Model Y from inventory stock, to qualify for these discounts. Offers both valid until 31st March 2025 and provided by Tesla.`,
    termsLinkUrl: "https://www.tesla.com/en_au/support/financing",  
    image: PromoTesla,
    imageMobile: PromoTeslaMobile,
  },
  {
    isTextImage: true,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: false,
    hideBanner: false, // true will remove this section from the slider
    titleBadge: "Exclusive for novated leases",
    vehicleTitle: "BYD Shark GL",
    vehicleSubTitle: "Qualifies for big EV discounts. Be one of the first in Australia to drive the BYD Shark.",
    priceDollar: "219",
    priceCents: "",
    period: "weekly*",
    disclaimerText:
      "* Based a on a driver in NSW for a 5 year lease term, with a $100,000 salary, and 15,000km driven annually.",
    image: PromoBYDShark,
    imageMobile: PromoBYDSharkMobile,
  },
  {
    isTextImage: true,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: false,
    hideBanner: false, // true will remove this section from the slider
    titleBadge: "Steer portal exclusive offer",
    vehicleTitle: "NISSAN X-TRAIL St-l (4WD) e-Power",
    vehicleSubTitle: "Enjoy an additional discount exclusive to Novated Lease Australia.",
    priceDollar: "263",
    priceCents: ".99",
    period: "weekly*",
    disclaimerText:
      "* Based a on a driver in NSW for a 5 year lease term, with a $100,000 salary, and 15,000km driven annually.",
    image: Promo1,
    imageMobile: PromoMobile1,
  },
  {
    isTextImage: true,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: false,
    hideBanner: true, // true will remove this section from the slider
    titleBadge: "Steer portal exclusive offer",
    vehicleTitle: "BYD Sealion 6 Dynamic",
    vehicleSubTitle: "Limited offer $2,500 cashback ordered between the 1st October 2024 to the 31st October 2024.",
    priceDollar: "182",
    priceCents: ".00",
    period: "weekly*",
    disclaimerText:
      "*Vehicles must be delivered by the 31st October 2024 to qualify. Only available on the new BYD Sealion 6 Dynamic model.",
    image: Promo2,
    imageMobile: PromoMobile2,
  },
];

export default dataSlider;
