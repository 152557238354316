import styled from 'styled-components';
import screenSize from '../../../styles/mediaQueries';
import EVBadge from '../../../images/svg/EV-badge.svg';
import YellowArtfacts from '../../../images/bg/comparison-yellow-artfacts.svg';

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;
 
  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`;

export const PromoBackground = styled.div`
  position: relative;
  width: 100%;
  background: #6629D7;
  margin-top: 3px;
  height: 700px;

  ${screenSize.minDesktop`
    height: 500px;
  `}
`;

export const PromoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 700px;    

  ${screenSize.minDesktop`
    height: 500px;
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 700px;

  ${screenSize.minDesktop`
    display: flex;
    flex-direction: row;
    height: 500px;
  `}
`;

export const PromoContent = styled.div`
  background: #6629d6;
  padding: 30px 40px 0px 40px;
  display: flex;
  flex-direction: column;

  ${screenSize.minDesktop`
    min-width: 520px;
    padding: 30px 30px 30px 120px;
  `}
`;

export const VehicleImg = styled.img`
`;

export const VehicleFullImg = styled.img`
  
`;

export const TitleBadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  ${screenSize.minDesktop`
    justify-content: flex-start;
  `}
`;

export const TitleBadge = styled.div`
  font-size: 18px;
  color: #6629d6;
  padding: 15px 25px 10px 25px;
  background: #FFFF00;
  border-radius: 8px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
`;

export const TitleEVBadge = styled.div`
  background-image: url(${EVBadge});
  background-repeat: no-repeat;
  background-size: 118px 38px;
  width: 118px;
  height: 38px;
`;

export const PromoContentTitle = styled.h1`
  color: ${(props) => props.color || ' #ffffff'};
  font-size: 40px;
  text-align: center;
  margin: 30px 0 0 0;  

  ${screenSize.minDesktop`
    max-width: 680px;
    text-align: left;
  `}
`;

export const PromoContentTitle1 = styled.h1`
  color: ${(props) => props.color || ' #ffffff'};
  font-size: 50px;
  text-align: center;
  margin: 30px 0 0 0;  

  ${screenSize.minDesktop`
    width: 680px;
    text-align: left;
  `}
`;

export const PromoContentTitle2 = styled.h2`
  color: ${(props) => props.color || ' #ffffff'};
  font-size: 40px;
  text-align: center;
  margin: 0 0 0 0;  

  ${screenSize.minDesktop`
    max-width: 680px;
    text-align: left;
  `}
`;

export const PromoContentSubTitle = styled.p`
  color: #ffffff;
  text-align: center;
  font-size: 26px;

  ${screenSize.minDesktop`
    max-width: 680px;
    text-align: left;
  `}
`;

export const PromoContentPrice = styled.div`
  color: #FF874F;
  font-size: 64px;
  text-align: center;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0.1rem;
  margin-bottom: 10px; 
  .small{
    font-size: 32px;
  }
  .from {
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 0;
    margin-right: 12px;
    vertical-align: middle;
  }
  .period {
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 0;
    margin-left: 12px;
    vertical-align: middle;
  }

  ${screenSize.minDesktop`
    text-align: left;
  `}
`;

export const PromoContentDisclaimerText = styled.p`
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  margin-top: 20px;

  ${screenSize.minDesktop`
    max-width: 500px;
    text-align: left;
  `}
`;

export const PromoContentTermsLink = styled.a`
  color: #ffffff;
  font-weight: 400;
  text-decoration: underline;
  text-align: center;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;

  ${screenSize.minDesktop`
    max-width: 400px;
    text-align: left;
  `}
`;

export const SliderNavArrowsWrapper = styled.div`
  width: 100%;
  height: 60px;
  position: absolute;
  top: 45%;
  z-index: 10;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
`;

export const SliderNavDotsWrapper = styled.div`
  width: 100%;
  max-width: 1590px;
  height: 30px;
  position: absolute;
  bottom: 0;
  z-index: 10;
  padding: 0 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

  .dot{
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
  }
  .dot.active {
    background-color: #FF874F;
  }
`;

export const YellowTopArtfacts = styled.div`
  background-image: url(${YellowArtfacts});
  background-repeat: no-repeat;

  ${screenSize.minDesktop`
    background-size: contain;
    background-position: top center;
    height: 100px;
  `}
`;

export const PartnerContentWrapper = styled.div`
  width: 100%;
  background: #6629d6;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

export const PartnerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
`;

export const PartnerTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PartnerName = styled.h1`
  color: #ffffff;
  font-size: 38px;
  text-align: center;
  margin: 0 0 10px 0;
`;

export const PartnerTitle = styled.h3`
  color: #ffffff;
  text-align: center;
  margin: 0;
`;

export const PartnerLogosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0 40px 0;
  gap: 20px;

  svg {
    color: #FF874F;
    font-size: 1.8em;
  }

  ${screenSize.minDesktop`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    gap: 30px;
  `}
`;

export const PartnerLogo = styled.div`
  height: 100px;
  width: 200px;
  background: #fff;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 80%;
    width: 120px;
  }

  .vehicle {
    height: auto;
    width: 140px;
  }
`;
